import { useStore } from '@nanostores/react'
import React, { useState, useEffect } from 'react'

import moduleEnv from '@/constants/env'
import { $isStickyCTAVisible } from '@/stores/ui/stores'
import type { CMSLiveChat } from '@/types/sanity'

import Icon from '../Icon/Icon'

import './Kustomer.scss'

const KUSTOMER_URL = 'https://cdn.kustomerapp.com/chat-web/widget.js'
const KUSTOMER_CHAT_DISCLAIMER_CONSENT_KEY =
  'sakara-kustomer-chat-disclaimer-accepted'
const KUSTOMER_CHAT_DISCLAIMER_CONSENT_VALUE = 'true'

type KustomerFormProps = {
  kustomerSettings: CMSLiveChat
}

const KustomerForm: React.FC<KustomerFormProps> = ({ kustomerSettings }) => {
  const [isKustomerStarted, setIsKustomerStarted] = useState(false)
  const [isAgentsOnline, setIsAgentsOnline] = useState(false)
  const [isChatButtonVisible, setIsChatButtonVisible] = useState(false)
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false)
  const [isDisclaimerSubmitted, setIsDisclaimerSubmitted] = useState(false)

  const isStickyVisible = useStore($isStickyCTAVisible)

  useEffect(() => {
    if (
      !kustomerSettings.displayChatButton ||
      document.querySelector(`script[src="${KUSTOMER_URL}"]`)
    ) {
      return
    }

    const script = document.createElement('script')
    script.src = KUSTOMER_URL
    script.setAttribute(
      'data-kustomer-api-key',
      moduleEnv.PUBLIC_KUSTOMER_API_KEY,
    )
    script.onload = () => {
      window.Kustomer.start(
        {
          brandId: moduleEnv.PUBLIC_KUSTOMER_BRAND_ID,
          hideChatIcon: true,
          hideHistory: true,
          lang: 'en',
        },
        () => {
          setIsKustomerStarted(true)
          window.Kustomer.close()
        },
      )
    }
    window.document.body.appendChild(script)
  }, [])

  useEffect(() => {
    setIsDisclaimerSubmitted(
      window.sessionStorage.getItem(KUSTOMER_CHAT_DISCLAIMER_CONSENT_KEY) ===
        KUSTOMER_CHAT_DISCLAIMER_CONSENT_VALUE,
    )
  }, [])

  useEffect(() => {
    if (isKustomerStarted) {
      const availability = window.Kustomer?.isChatAvailable().availability
      setIsChatButtonVisible(
        availability === 'online' || availability === 'offline',
      )
      setIsAgentsOnline(availability === 'online')
    }
  }, [isKustomerStarted])

  const openKustomer = () => {
    if (window.Kustomer) {
      window.Kustomer.getOpenConversations(
        (conversations: { conversationId: string }[], error?: Error) => {
          if (!error && conversations.length > 0) {
            window.Kustomer.openConversationById(conversations[0])
          } else {
            window.Kustomer.createConversationInDraft()
          }
        },
      )
    } else {
      console.error('Kustomer is not available.')
    }
  }

  const handleChatButtonClick = () => {
    if (isDisclaimerSubmitted) {
      openKustomer()
    } else {
      setIsDisclaimerVisible(true)
    }
  }

  const closeDisclaimer = () => {
    setIsDisclaimerVisible(false)
  }

  const submitDisclaimer = (event: React.FormEvent) => {
    event.preventDefault()
    closeDisclaimer()
    setIsDisclaimerSubmitted(true)
    openKustomer()
    window.sessionStorage.setItem(
      KUSTOMER_CHAT_DISCLAIMER_CONSENT_KEY,
      KUSTOMER_CHAT_DISCLAIMER_CONSENT_VALUE,
    )
  }

  const imageSrc =
    'https://cdn.shopify.com/s/files/1/0192/0076/files/chat-icon.png'

  return (
    <>
      {isChatButtonVisible && (
        <button
          className={`c-kustomer-form__button type-label ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
          onClick={handleChatButtonClick}
        >
          Chat
          <div style={{ position: 'relative' }}>
            <img
              src={imageSrc}
              alt="Chat bot"
              className="c-kustomer-form__chat-icon"
            ></img>
            {isAgentsOnline && (
              <div className="c-kustomer-form__chat-online-icon"></div>
            )}
          </div>
        </button>
      )}
      {isDisclaimerVisible && (
        <div
          className={`c-kustomer-form__form ${
            isStickyVisible ? 'is-stuck' : ''
          }`}
        >
          <div className="c-kustomer-form__header">
            <p className="c-kustomer-form__title type-label">
              CHAT WITH SUPPORT
            </p>
            <button
              className="c-kustomer-form__disclaimer-close"
              onClick={closeDisclaimer}
              onKeyUp={closeDisclaimer}
            >
              <Icon name="cross" />
            </button>
          </div>
          <form
            className="c-kustomer-form__content"
            onSubmit={submitDisclaimer}
          >
            <p className="c-kustomer-form__content-header type-label">
              Disclaimer
            </p>
            <p className="c-kustomer-form__content-text c-kustomer-form__content-text--subheader type-body">
              By using this chat, you:
            </p>
            <ol type="1">
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                understand that an automated chatbot will collect and process
                any information you provide, and you may request to speak with a
                live agent during normal business hours (9 AM - 5PM ET).
              </li>
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                understand that any information you provide may be monitored and
                retained, including by our service providers.
              </li>
              <li className="c-kustomer-form__content-text c-kustomer-form__content-text--list type-body">
                are accepting and consenting to the practices described in our{' '}
                <a href="/pages/privacy-policy">Privacy Policy.</a>
              </li>
            </ol>
            <button
              type="submit"
              className="button button--tertiary c-kustomer-form__submit-button"
            >
              Get Started
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default KustomerForm
